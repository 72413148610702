import Button from '@components/Button';
import ColumnLayout from '@components/ColumnLayout';
import Loader from '@components/Loader';
import SEO from '@components/seo';
import AuthUserContext from '@context/AuthUserContext';
import imageUrlFor from '@helpers/imageUrlFor';
import FormError from '@utility/FormError';
import FormHoneypot from '@utility/FormHoneypot';
import FormInput from '@utility/FormInput';
import Link from '@utility/Link';
import cn from 'classnames';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  EMAIL_ERROR_MESSAGE,
  EMAIL_FIELD_PATTERN,
  REQUIRED_FIELD,
} from '../../../common';
import withFirebase from '../Firebase/withFirebase';
import { getCanonicalUrl } from '@src/helpers/seoHelper';

const Login = ({ firebase }) => {
  const { authUser, authHasConnected } = useContext(AuthUserContext);
  const accountData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawLoginAccount(resolveReferences: { maxDepth: 5 })
            }
          }
        }
      }
    `,
  );
  const { register, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
  });

  const { errors } = formState;

  const [submitError, setSubmitError] = useState(null);
  const { _rawLoginAccount } = accountData.allSanityAccount.edges[0].node;
  const throwSubmitError = (error) => {
    setSubmitError(error);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const onSubmit = (data) => {
    firebase
      .firebaseSignInWithEmailAndPassword(data.email, data.password)
      .then((signedInUser) =>
        // * refresh token in case user is already logged in
        // // occurs in sign up flow if user revisits same
        // // tab on email verification
        signedInUser.getIdToken(true),
      )
      .then(() => firebase.auth.currentUser.reload())
      .then(() => {
        if (!firebase.auth.currentUser.emailVerified) {
          // check against currentUser emailVerified
          // and throw custom error
          setSubmitError({
            a: null,
            code: 'auth/email-not-verified',
            message: '',
          });
          firebase.firebaseSignOut();
        } else {
          navigate('/account/projects');
        }
      })
      .catch((error) => {
        throwSubmitError(error);
      });
  };
  
  const routeUser = () => {
    if (authUser && authUser?.emailVerified) {
      // If user already is already logged in
      // navigate to their account
      if (localStorage.getItem('previousAccountRoute')) {
        navigate(localStorage.getItem('previousAccountRoute'), {
          replace: true,
        });
      } else {
        navigate('/account/projects', { replace: true });
      }
      return null;
    }

    const handleResendClick = () => {
      let uuid=''
      firebase
        .firebaseSignInWithEmailAndPassword(
          getValues('email'),
          getValues('password'),
        )
        .then((authUser) => uuid = authUser.uid)
        .then(() => firebase.sendVerficationCodeToMail({email: getValues('email')}))
        .then(() => {
          return navigate('/account/verify-inbox', {state: {email: getValues('email'), uuid, password: getValues('password') }});
        }) 
    }

    // user is not logged in or has not verified their account
    return (
      <ColumnLayout
        heading={_rawLoginAccount.heading ? _rawLoginAccount.heading : ''}
        subheading={
          _rawLoginAccount.subheading ? _rawLoginAccount.subheading : ''
        }
        image={_rawLoginAccount.image && _rawLoginAccount.image}
      >
        <SEO
          title={_rawLoginAccount?.heading || null}
          description={_rawLoginAccount?.subheading || null}
          image={
            _rawLoginAccount?.image?.asset
              ? imageUrlFor(_rawLoginAccount.image).url()
              : null
          }
        >
          <link rel="canonical" href={getCanonicalUrl('/account/login')} />
        </SEO>
        {submitError?.code && (
          <FormError key={submitError.code}>
            {submitError.code === 'auth/email-not-verified' ? (
              <p>
                Please <span className='underline' onClick={handleResendClick}>verify your account</span>. Don&apos;t see a verification
                email?&nbsp;
                <button
                  className="underline focus:outline-none type-sans-030"
                  type="button"
                  onClick={handleResendClick}
                  aria-label='Resend Email'
                >
                  Resend Email
                </button>
              </p>
            ) : (
              <p>{submitError.message}</p>
            )}
          </FormError>
        )}
        {/* "handleSubmit" will validate your FormInputs before invoking "onSubmit" */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            label="Email Address"
            type="text"
            placeholder="Enter your email address"
            {...register('email', {
              required: true,
              pattern: EMAIL_FIELD_PATTERN,
            })}
            errorMessage={
              errors.email &&
              (errors.email.type === 'required'
                ? REQUIRED_FIELD
                : EMAIL_ERROR_MESSAGE)
            }
          />
          <FormInput
            label="Password"
            {...register('password', { required: true, minLength: 6 })}
            type="password"
            placeholder="Enter your password"
            errorMessage={
              errors.password &&
              (errors.password.type === 'required'
                ? REQUIRED_FIELD
                : 'This password is too weak, passwords should be atleast 6 characters.')
            }
          />
          <FormHoneypot register={register} />
          <Button
            type="submit"
            className={cn('btn-default btn-black btn-lg mt-4', {
              'btn-black-disabled': !formState.isValid,
            })}
            aria-label='Log In'
          >
            Log In
          </Button>
        </form>
        <div className="lg:flex justify-between mt-6">
          <p className="type-sans-030 mb-4">
            <Link
              to="/account/reset-password"
              styled={false}
              className="inline-link"
              aria-label="Forgot Password?"
            >
              Forgot Password?
            </Link>
          </p>

          <p className="type-sans-030">
            Don&apos;t have a Profile?&nbsp;
            <Link to="/account/create" styled={false} className="inline-link" aria-label="Create Profile">
              Create Profile
            </Link>
          </p>
        </div>
      </ColumnLayout>
    );
  };
  // make sure an established connection to firebase auth has occured before routing
  return <div>{!authHasConnected ? <Loader fullPage /> : routeUser()}</div>;
};

Login.propTypes = {
  firebase: PropTypes.shape({
    firebaseSignInWithEmailAndPassword: PropTypes.func,
    onAuthUserListener: PropTypes.func,
    firebaseSendEmailVerification: PropTypes.func,
    firebaseSignOut: PropTypes.func,
    auth: PropTypes.object,
  }).isRequired,
};

export default withFirebase(Login);
