import React from 'react';
import { graphql } from 'gatsby';
import imageUrlFor from '@helpers/imageUrlFor';
import CategoryNavigation from '@components/CategoryNavigation';
import ImageTextOverlap from '@components/ImageTextOverlap';
import ProductCard from '@components/ProductCard';
import useFinishCategories from '@utility/useFinishCategories';
import ScrollInView from '@utility/ScrollInView';
import SEO from '@components/seo';
import { getCanonicalUrl } from '@src/helpers/seoHelper';

export const query = graphql`
  query ($id: String) {
    allSanityFinish(
      filter: { category: { id: { eq: $id } }, isHidden: { ne: true } }
      sort: { fields: orderRank, order: ASC }
    ) {
      edges {
        node {
          ...finishesFragment
        }
      }
    }
  }
`;

const FinishesTemplate = ({
  data: { allSanityFinish },
  pageContext: { id, title, _rawCategoryPageHero, urlPath },
}) => {
  const finishCategories = useFinishCategories();
  const finishCatNode = finishCategories.allSanityFinishCategory.edges[0]?.node;
  return (
    <>
      <SEO
        title={`${title} Finish`}
        description={
          (finishCatNode?._rawDescription?.richText &&
            finishCatNode?._rawDescription?.richText[0].children[0]?.text) ||
          null
        }
        image={
          _rawCategoryPageHero?.image?.asset
            ? imageUrlFor(_rawCategoryPageHero.image).url()
            : null
        }
      >
        <link rel="canonical" href={getCanonicalUrl(`${urlPath}`)} />
      </SEO>
      <CategoryNavigation
        heading="Finish Library"
        defaultPath="/finishes"
        categories={finishCategories.allSanityFinishCategory.edges}
      />
      {_rawCategoryPageHero && (
        <div className="mb-40">
          <ImageTextOverlap data={_rawCategoryPageHero} />
        </div>
      )}
      {allSanityFinish && (
        <div className="wrapper">
          <ScrollInView>
            <div className="row">
              {allSanityFinish.edges.map((finish) => (
                <div key={finish.node.id} className="col-6 lg:col-3">
                  <ProductCard data={{ ...finish.node }} isFinish />
                </div>
              ))}
            </div>
          </ScrollInView>
        </div>
      )}
    </>
  );
};

export default FinishesTemplate;
