import React, { useEffect, useState } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import PropTypes from 'prop-types';
import { Configure, Stats, InstantSearch } from 'react-instantsearch-dom';
import ProductHits from '@components/ProductSearch/hits';
import ProductPagination from '@components/ProductSearch/pagination';
import ProductSearchBox from '@components/ProductSearch/searchBox';
import SearchSuggestions from '@components/SearchSuggestions';
import ScrollInView from '@utility/ScrollInView';
import isSku from '@utility/isSkuSearch';
import skuDecoder from '@utility/skuDecoder';
import SEO from '../components/seo';
import { PRODUCTS_HITS_PER_PAGE } from '../../common';
import { getCanonicalUrl, isParameterizedURL } from '@src/helpers/seoHelper';

const createURL = (state, skuDecoderData) => {
  const LOCATION_PATHNAME = '/search'; // should match filename or slug of page
  const isDefaultRoute = !state.query && state.page === 1;

  if (isDefaultRoute) {
    return LOCATION_PATHNAME;
  }

  const queryParameters = {};

  if (state.query) {
    queryParameters.query = encodeURIComponent(state.query);
  }

  if (state.page !== 1) {
    queryParameters.page = state.page;
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  return `${LOCATION_PATHNAME}${queryString}`;
};

// Handles creating the URL with parameters based on refinements
const searchStateToUrl = (searchState, skuDecoderData) => {
  // console.log('Search State to URL:', searchState);
  return searchState ? createURL(searchState, skuDecoderData) : '';
};

// Handles taking the URL parameters and setting the search state to initialize with
const urlToSearchState = (location) => {
  const { query, page = 1 } = qs.parse(location.search.slice(1));

  return {
    query: query ? decodeURIComponent(query) : '',
    page,
  };
};

export const skuQuery = graphql`
  query {
    productSkus: allSanitySku(filter: { options: {ne: null}}) {
      nodes {
        _id
      }
    }
    products: allSanityProduct {
      nodes {
        partial_sku
        ipRating
        certification
        inputVoltage
        dimmingControl
        lang
        productOptionList
        searchWeighting
        slugPrefix {
          current
        }
      }
      distinct(field: productOptionList)
    }
  }`

const SearchPage = ({ location, data: { productSkus, products, distinct } }) => {
  const HITS_PER_PAGE = PRODUCTS_HITS_PER_PAGE;
  const DEBOUNCE_URL_TIME = 700;
  const [isLoading, setLoading] = useState(false);
  const [isQueryState, setQueryState] = useState(false);
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [skuSearch, setSkuSearch] = useState('');
  const [debouncedSetState, setDebouncedSetState] = useState(null);
  const skuDecoderData = { productSkus, products, distinct }

  useEffect(() => {
    toggleQueryState(searchState);
  }, [searchState]);

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  useEffect(() => {
    (async () => {
      const { skuDecoded, slugPrefix, skuSuffix } = skuDecoder({ codifiedSKU: searchState.query, skuDecoderData })
      if (skuDecoded) {
        navigate(`/${slugPrefix}/${skuSuffix.toLowerCase()}`)
      }
      const isSkuResult = await isSku(searchState);
      setSkuSearch(isSkuResult);
    })();
  }, [searchState])

  const toggleQueryState = (state) => {
    setQueryState(state.query && state.query.trim() != '');
  };

  const onSearchStateChange = (updatedSearchState) => {
    // To fake loading of product cards
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);

    // Pushes to and navigates refinement parameters to URL
    clearTimeout(debouncedSetState);
    setDebouncedSetState(
      setTimeout(() => {
        navigate(searchStateToUrl(updatedSearchState, skuDecoderData));
      }, DEBOUNCE_URL_TIME),
    );

    // console.log('Set Search State:', updatedSearchState);
    setSearchState(updatedSearchState);
  };

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  );

  if (skuSearch !== '') {
    console.log('add secret params to search client', { skuSearch })
    // parse the query as a sku

  }

  return (
    <div className="mb-16">
      <SEO title="Search Results">
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
        {isParameterizedURL(location.search) && (
          <meta name="robots" content="noindex" />
        )}
      </SEO>
      <ScrollInView animateType="fadeInUp">
        <InstantSearch
          searchClient={searchClient}
          indexName={`${process.env.GATSBY_ALGOLIA_PREFIX}global`}
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
          createURL={createURL}
        >
          <Configure hitsPerPage={HITS_PER_PAGE} />
          <ProductSearchBox />
          {isQueryState ? (
            <div className="mt-20">
              <div className="wrapper">
                <Stats
                  translations={{
                    stats(nbHits) {
                      return (
                        <div className="type-sans-430 md:type-sans-530 mb-10">
                          Product Matches{' '}
                          <span className="text-mono-400">{`(${nbHits})`}</span>
                        </div>
                      );
                    },
                  }}
                />
              </div>
              <ProductHits isLoading={isLoading} />
            </div>
          ) : (
            <ScrollInView animateType="fadeInUp">
              <div className="wrapper">
                <div className="mt-20 lg:mt-8" style={{ minHeight: '300px' }}>
                  <SearchSuggestions />
                </div>
              </div>
            </ScrollInView>
          )}
          {isQueryState && <ProductPagination />}
        </InstantSearch>
      </ScrollInView>
    </div>
  );
};

SearchPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default SearchPage;
