import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import CategoryNavigation from '@components/CategoryNavigation';
import ProductCard from '@components/ProductCard';
import ImageTextOverlap from '@components/ImageTextOverlap';
import useFinishCategories from '@utility/useFinishCategories';
import ScrollInView from '@utility/ScrollInView';
import SEO from '@components/seo';
import { getCanonicalUrl } from '@src/helpers/seoHelper';

export const finishesFragment = graphql`
  fragment finishesFragment on SanityFinish {
    id
    title
    price
    _type
    image {
      asset {
        _id
      }
      altText
    }
    discountPrice
    code
    orderRank
    category {
      id
      title
    }
    sampleDownload
  }
`;

const Finishes = () => {
  const finishCategories = useFinishCategories();
  const finishes = useStaticQuery(
    graphql`
      query {
        finishLibrary: allSanityFinishLibrary(filter: { id: { ne: null } }) {
          edges {
            node {
              metaDescription
              _rawLibraryPageHero(resolveReferences: { maxDepth: 5 })
            }
          }
        }
        allSanityFinish(
          filter: { isHidden: { ne: true } }
          sort: { fields: orderRank, order: ASC }
        ) {
          edges {
            node {
              ...finishesFragment
            }
          }
        }
      }
    `,
  );

  // mock
  const finishesPageHero =
    finishes.finishLibrary.edges[0].node._rawLibraryPageHero;
  const finishesMetaDesc = finishes.finishLibrary.edges[0].metaDescription
  return (
    <div>
      <SEO title={'RBW Finish Library'} description={finishesMetaDesc || null}>
        <link rel="canonical" href={getCanonicalUrl('/finishes')} />
      </SEO>
      <CategoryNavigation
        heading="Finish Library"
        defaultPath="/finishes"
        categories={finishCategories.allSanityFinishCategory.edges}
      />
      {finishesPageHero && (
        <div className="mb-40">
          <ImageTextOverlap data={finishesPageHero} />
        </div>
      )}
      {finishCategories &&
        finishCategories.allSanityFinishCategory.edges.map((category) => {
          if (category.node.isHidden) return false;

          const description =
            category.node._rawDescription &&
            category.node._rawDescription.richText
              ? category.node._rawDescription.richText[0].children[0].text
              : null;
          return (
            <ScrollInView key={category.node.id} className="wrapper">
              <div className="mb-14 lg:mb-40">
                <div className="row">
                  <div className="col-12 xl:col-3 mb-12 xl:mb-0">
                    <h2 className="mb-6 md:mb-7 type-sans-830 md:type-sans-930">
                      {category.node.title}
                    </h2>
                    {description && (
                      <p className="type-sans-330 xl:pr-16">{description}</p>
                    )}
                  </div>
                  {finishes &&
                    finishes.allSanityFinish.edges.map((finish) => {
                      if (category.node.id === finish.node.category.id) {
                        return (
                          <div key={finish.node.id} className="col-6 lg:col-3">
                            <ProductCard
                              data={{ ...finish.node }}
                              isFinish
                            ></ProductCard>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </ScrollInView>
          );
        })}
    </div>
  );
};

Finishes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Finishes;
