import PropTypes from 'prop-types';
import React from 'react';
import Link from '@utility/Link';
import imageUrlFor from '@helpers/imageUrlFor';
import ScrollInView from '@utility/ScrollInView/index';
import * as styles from './styles';
import ContentWithSuperscript from '../CollectionTitle';

const ContentBox = ({
  data: { heading, subheading, link, logo, paragraph },
  transparent,
}) => {
  return (
    <ScrollInView
      className={`p-6 md:py-8 md:px-10 ${
        transparent ? 'bg-transparent' : 'bg-white'
      }`}
    >
      {logo && logo[0] && (
        <img
          src={imageUrlFor(logo[0]).maxHeight(65).url()}
          alt={logo[0].altText ? logo[0].altText : ''}
          className={styles.logo}
        />
      )}
      {/* {subheading && (
        <p className="type-upper-070 text-mono-400 mt-1 mb-2">{subheading}</p>
      )} */}
      <h2 className="type-sans-630 lg:type-sans-930 mb-4 md:mb-6"><ContentWithSuperscript content={heading} /></h2>
      <p className="type-sans-330 text-mono-500">
        <ContentWithSuperscript content={paragraph} />
        
        {link && link[0] && (
          <span className="mt-6 md:mt-8 block">
            <Link
              internalExternal
              to={link[0]}
              className="type-sans-030"
              aria-label={link[0].ariaLabel ?? link[0].label}
            >
              <ContentWithSuperscript content={link[0].label} />
            </Link>
          </span>
        )}
      </p>
    </ScrollInView>
  );
};

ContentBox.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    paragraph: PropTypes.string.isRequired,
    logo: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.arrayOf(PropTypes.object),
  }),
};

ContentBox.defaultProps = {
  data: {
    subheading: '',
    link: [],
    logo: [],
  },
};

export default ContentBox;
