import React from 'react';
import PropTypes from 'prop-types';
import imageUrlFor from '@helpers/imageUrlFor';
import HeroPrimary from '@components/HeroPrimary';
import HeroSecondary from '@components/HeroSecondary';
import Hireology from '@components/Hireology';
import Components from '@components/Components';
import SEO from '@components/seo';
import { getCanonicalUrl, isParameterizedURL } from '@src/helpers/seoHelper';
import { useLocation } from '@reach/router';

const PageTemplate = ({
  pageContext: { title, urlPath, _rawHeroComponent, _rawComponents },
}) => {
    const location = useLocation();
    return (<div className="mb-10">
    <SEO
      title={title}
      description={_rawHeroComponent[0]?.metaDescription || _rawHeroComponent[0]?.description || null}
      image={
        _rawHeroComponent[0]?.image?.asset
          ? imageUrlFor(_rawHeroComponent[0].image).url()
          : null
      }
    >
      {isParameterizedURL(location.search) && (
        <meta name="robots" content="noindex" />
      )}
      <link rel="canonical" href={getCanonicalUrl(`/${urlPath}`)} />
    </SEO>
    {_rawHeroComponent && _rawHeroComponent[0]._type === 'heroPrimary' ? (
      <HeroPrimary data={_rawHeroComponent[0]} />
    ) : (
      <HeroSecondary data={_rawHeroComponent[0]} />
    )}
    {_rawComponents &&
      _rawComponents.map((element) => (
        <div className="py-12 md:py-20" key={element._key}>
          {React.createElement(Components(element._type), {
            key: element._key,
            data: element,
          })}
        </div>
      ))}
  </div>)
};

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string,
    urlPath: PropTypes.string,
    _rawHeroComponent: PropTypes.arrayOf(PropTypes.object),
    _rawComponents: PropTypes.arrayOf(PropTypes.object),
  }),
};

PageTemplate.defaultProps = {
  pageContext: {
    title: '',
    urlPath: '',
    _rawHeroComponent: [],
    _rawComponents: [],
  },
};

export default PageTemplate;
