import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import imageUrlFor from '@helpers/imageUrlFor';
import BlogListing from '@components/BlogListing';
import CategoryNavigation from '@components/CategoryNavigation';
import Pagination from '@components/Pagination';
import useBlogCategories from '@utility/useBlogCategories';
import values from 'lodash.values';
import mapValues from 'lodash.mapvalues';
import SEO from '@components/seo';
import { getCanonicalUrl } from '@src/helpers/seoHelper';

export const query = graphql`
  query($id: String, $skip: Int!, $limit: Int!) {
    allSanityBlogPost(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          ...blogPostsFragment
        }
      }
    }
    sanityBlogCategory(id: { eq: $id }) {
      metaDescription
      metaImage {
        asset {
          _id
        }
      }
      title
    }
    allSanityBlogPage {
      edges {
        node {
          _id
          title
        }
      }
    }
  }
`;

const BlogCategoryTemplate = ({
  data: { allSanityBlogPost, allSanityBlogPage, sanityBlogCategory },
  pageContext: { currentPage, numBlogPages, defaultPath },
}) => {
  const blogCategories = useBlogCategories();
  const posts = values(
    mapValues(allSanityBlogPost.edges, (post) => {
      return post.node;
    }),
  );

  return (
    <div>
      <SEO
        title={sanityBlogCategory.title}
        description={sanityBlogCategory?.metaDescription || null}
        image={
          sanityBlogCategory?.metaImage?.asset
            ? imageUrlFor(sanityBlogCategory.metaImage).url()
            : null
        }
      >
        <link rel="canonical" href={getCanonicalUrl(`${defaultPath}`)} />
      </SEO>
      <CategoryNavigation
        heading={allSanityBlogPage.edges[0].node.title}
        defaultPath="/blog"
        categories={blogCategories.allSanityBlogCategory.edges}
      />
      <div className="mb-12">
        <BlogListing data={{ posts }} />
      </div>
      {numBlogPages && numBlogPages > 0 && (
        <Pagination
          data={{
            currentPage,
            numPages: numBlogPages,
            path: defaultPath,
          }}
        />
      )}
    </div>
  );
};

BlogCategoryTemplate.propTypes = {
  data: PropTypes.shape({
    allSanityBlogPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allSanityBlogPage: PropTypes.shape({
      edges: PropTypes.array,
    }),
    sanityBlogCategory: PropTypes.object,
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numBlogPages: PropTypes.number,
    defaultPath: PropTypes.string,
  }),
};

BlogCategoryTemplate.defaultProps = {
  data: {
    allSanityBlogPost: {},
    allSanityBlogPage: {},
    sanityBlogCategory: {},
  },
  pageContext: PropTypes.shape({
    currentPage: 1,
    numBlogPages: 0,
    defaultPath: '',
  }),
};

export default BlogCategoryTemplate;
