import { TM_COLLECTIONS } from '../../../common';
import React from 'react';

const addSuperscriptToTrademarks = (text) => {
  if (!text) return text;

  // Create a regex pattern dynamically from TM_COLLECTIONS
  const pattern = new RegExp(`\\b(${TM_COLLECTIONS.join('|')})\\b`, 'gi');

  // Replace matched terms with the superscript
  return text.replace(pattern, (match) => `${match}<sup>®</sup>`);
};

const ContentWithSuperscript = ({ content }) => {
  // Replace "Ripple" with formatted version
  const processedContent = addSuperscriptToTrademarks(content);

  return <span dangerouslySetInnerHTML={{ __html: processedContent }} />;
};

export default ContentWithSuperscript;
