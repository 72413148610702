import React from 'react';
import { graphql } from 'gatsby';
import imageUrlFor from '@helpers/imageUrlFor';
import PropTypes from 'prop-types';
import BlogListing from '@components/BlogListing';
import HeroPrimary from '@components/HeroPrimary';
import Components from '@components/Components';
import SEO from '@components/seo';
import { getCanonicalUrl } from '@src/helpers/seoHelper';

export const query = graphql`
  query($id: String) {
    allSanityProduct(filter: { collection: {elemMatch: { id: { eq: $id } } }}, sort: {fields: orderRank, order: ASC}) {
      edges {
        node {
          id
          partial_sku
          title
          price
          isNew
          hasHoverImage,
          hoverImageType,
          _type
          downloads {
            drawings
            revit
            sketchUp
            ies
            techPack
            installInstructions
            _type
            _key
            other {
              title
              link
            }
            other2 {
              title
              link
            }
          }
          slug {
            current
          }
          slugPrefix {
            current
          }
          orderRank
          productType {
            id
            title
          }
          showOnProductCard
          marking {
            _id
          }
        }
      }
    }
  }
`;

const CollectionTemplate = ({
  data: { allSanityProduct },
  pageContext: { _rawComponents, _rawHeroPrimary, blogListing },
}) => (
  <div>
    <HeroPrimary data={_rawHeroPrimary} alignment="top center" />
    <div>
      <SEO
        title={`Explore ${_rawHeroPrimary.title} Collection From RBW | Top Designer LED Lighting Manufacturer Company in New York`}
        description={
          _rawHeroPrimary[0]?.metaDescription || _rawHeroPrimary[0]?.description || null
        }
        image={
          _rawHeroPrimary?.image
            ? imageUrlFor(_rawHeroPrimary.image).url()
            : null
        }
      >
        <link rel="canonical" href={getCanonicalUrl(`/collections/${_rawHeroPrimary.title.toLowerCase()}`)} />
      </SEO>
      {_rawComponents &&
        _rawComponents.map((element) => (
          <div className="py-12 md:py-20" key={element._key}>
            {React.createElement(Components(element._type), {
              key: element._key,
              data: element,
              productByCollection:
                element._type === 'productCarousel'
                  ? allSanityProduct.edges
                  : [],
            })}
          </div>
        ))}
    </div>
    <div className="pt-12 pb-24 md:pt-20 md:pb-40">
      <BlogListing data={blogListing !== null ? blogListing : {}} />
    </div>
  </div>
);

CollectionTemplate.propTypes = {
  pageContext: PropTypes.shape({
    _rawHeroPrimary: PropTypes.object,
    _rawComponents: PropTypes.arrayOf(PropTypes.object),
    blogListing: PropTypes.object,
  }),
  data: PropTypes.shape({
    allSanityProduct: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

CollectionTemplate.defaultProps = {
  pageContext: {
    _rawHeroPrimary: {},
    blogListing: {},
    _rawComponents: [],
  },
  data: {
    allSanityProduct: {},
  },
};

export default CollectionTemplate;
