import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ScrollInView from '@utility/ScrollInView';
import FluidImage from '@utility/FluidImage/index';
import useScrollPosition from '@utility/useScrollPosition';
import * as styles from './styles';
import ContentWithSuperscript from '../CollectionTitle';

const HeroPrimary = ({
  data: { title, image, description },
  alignment,
  halfHeight,
}) => {
  const [scrollPosition, setSrollPosition] = useState(0);
  const [ref, inView] = useInView();
  useScrollPosition(
    ({ currPos }) => {
      setSrollPosition(currPos);
    },
    [inView],
    null,
    true,
    10,
  );
  return (
    <div>
      <div className={`${styles.hero} pb-12 md:pb-20`} ref={ref}>
        <FluidImage
          src={image}
          maxWidth={3200}
          maxHeight={1920}
          mobileRatio={1.2}
          quality={100}
          style={{
            objectPosition: alignment,
          }}
          className={cn(styles.heroImage, {
            [styles.heroImageHalf]: halfHeight,
          })}
          loading="eager"
        />
        <div
          className={cn(styles.heroTransform, styles.heroTransformTop)}
          style={{
            transform: `translate3d(0, ${-100 + scrollPosition * 0.005}%, 0)`,
          }}
        />
        <div
          className={cn(styles.heroTransform, styles.heroTransformLeft)}
          style={{
            transform: `translate3d(${-100 + scrollPosition * 0.02}%, 0, 0)`,
          }}
        />
        <div
          className={cn(styles.heroTransform, styles.heroTransformRight)}
          style={{
            transform: `translate3d(${100 - scrollPosition * 0.02}%, 0, 0)`,
          }}
        />
        <ScrollInView className="wrapper">
          <div className={`row ${styles.heroTitleContainer}`}>
            <div className="col-12">
              <h1 className={styles.heroTitle}><ContentWithSuperscript content={title} /></h1>
            </div>
            <div className="col-12 md:col-9">
              <p className={styles.heroDescription}><ContentWithSuperscript content={description} /></p>
            </div>
          </div>
        </ScrollInView>
      </div>
    </div>
  );
};

HeroPrimary.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.shape({
      asset: PropTypes.shape({
        _id: PropTypes.string,
      }),
    }),
    description: PropTypes.string,
  }).isRequired,
  alignment: PropTypes.string,
  halfHeight: PropTypes.bool,
};

HeroPrimary.defaultProps = {
  alignment: 'center center',
};
export default HeroPrimary;
